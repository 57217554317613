import styled from "styled-components"

export const Wrapper = styled.div`
  margin: -15px;
  padding-bottom: 64px;
`

export const Row = styled.div`
  padding: 15px;
`

export const Thumbnails = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: -15px;
  padding: 0;
`

export const Articles = styled.div`
  margin: -15px;
`
