import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  flex-shrink: 1;
  margin: 15px;
`

export const Video = styled.video`
  border-radius: 0;
  width: 100%;
  height: auto;
  outline: 0;
`

export const ImageWrapper = styled.div``

export const Image = styled.img`
  display: block;
  height: 100%;
  margin: 0;
  width: 100%;
`
