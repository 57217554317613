import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { typography } from '../../theme';

import PlayIcon__White from '../../images/play-white.svg';

export const ImageWrapper = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  ${props =>
    props.withPlayIcon &&
    css`
      &::after {
        background-image: url(${PlayIcon__White});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left bottom;
        bottom: 30px;
        content: '';
        height: 36px;
        left: 30px;
        position: absolute;
        width: 36px;
      }
    `}
`;

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

export const Title = styled.header`
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 30px 45px 30px 30px;
  position: absolute;
  right: 0;
  top: 0;

  h1,
  h2 {
    color: #fff !important;
    margin: 0;
    transition: transform 0.3s ease-in-out;
  }
`;

export const Footer = styled.footer`
  font-size: ${typography.FONT_SIZE__XSMALL__REM}rem;
  line-height: ${typography.LINE_HEIGHT__XSMALL__REM}rem;
  padding-top: 6px;
  position: absolute;
`;

export const Index = styled.span``;

export const Category = styled.span`
  cursor: pointer;
  text-transform: capitalize;
  padding-left: 40px;
`;

export const InnerWrapper = styled(Link)`
  display: block;
  position: relative;
  text-decoration: none;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%;
  }

  &:hover {
    ${Title} {
      h1,
      h2 {
        transform: translate3d(10px, 0, 0);
      }
    }
  }
`;

export const Wrapper = styled.li`
  filter: grayscale(${props => (props.isInactive ? '100%' : '0%')});
  margin: 0;
  opacity: ${props => (props.isInactive ? 0.3 : 1)};
  padding: 0 15px 30px 15px;
  transition: filter 0.3s ease-in-out, opacity 0.3s ease-in-out;
  width: ${props => 100 / props.numColumns}%;

  ${props =>
    props.isArchive &&
    css`
      ${Title} {
        border: solid 10px;
        justify-content: flex-end;
        padding: 20px;

        h1 {
          color: #000 !important;
          font-size: ${typography.FONT_SIZE__XSMALL__REM}rem;
          line-height: ${typography.LINE_HEIGHT__XSMALL__REM}rem;
          margin: 0;
        }
      }
    `}
`;
