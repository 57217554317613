import React, { useContext } from 'react';
import GatsbyImage from 'gatsby-image';
import { ThumbnailsState } from '../../store';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import {
  Wrapper,
  InnerWrapper,
  ImageWrapper,
  Title,
  Footer,
  Index,
  Category,
} from './style';
import { layout } from '../../theme';

const PostThumbnail = ({
  post: {
    slug,
    title,
    subtitle,
    thumbnail,
    category: { title: category },
    number,
    media,
  },
  onClick,
}) => {
  const { breakpoint } = useWindowDimensions();
  const { activeCategory } = useContext(ThumbnailsState.State);
  const globalStateDispatch = useContext(ThumbnailsState.Dispatch);
  const isArchive = category === 'Archive';

  return (
    <Wrapper
      isArchive={isArchive}
      isInactive={activeCategory && activeCategory !== category}
      numColumns={layout.THUMBNAIL_COLUMN_COUNT[breakpoint]}
    >
      <InnerWrapper to={`/posts/${slug}`} onClick={onClick}>
        <ImageWrapper withPlayIcon={!!media}>
          <GatsbyImage
            fluid={thumbnail.asset.fluid}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          />
        </ImageWrapper>
        {isArchive ? (
          <Title>
            <h1>
              {title}&mdash;{subtitle}
            </h1>
          </Title>
        ) : (
          <Title>
            <h1>{title}</h1>
            <h2>&mdash;{subtitle}</h2>
          </Title>
        )}
      </InnerWrapper>
      <Footer>
        <Index>{number}.</Index>
        <Category
          onClick={evt => {
            evt.preventDefault();
            globalStateDispatch({
              type: 'setActiveCategory',
              activeCategory: activeCategory === category ? null : category,
            });
          }}
        >
          {category}
        </Category>
      </Footer>
    </Wrapper>
  );
};

export default PostThumbnail;
