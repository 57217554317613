import React from 'react';
import GatsbyImage from 'gatsby-image';

import { Wrapper, ImageWrapper } from './style';

const ContentBlockMedia = ({ image }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <GatsbyImage fluid={image.asset.fluid} />
      </ImageWrapper>
    </Wrapper>
  );
};

export default ContentBlockMedia;
