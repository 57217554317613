import styled from "styled-components"

export const Wrapper = styled.article`
  bottom: 0;
  display: ${props => (props.isOpen ? "block" : "none")};
  left: 0;
  position: fixed;
  right: 0;
  z-index: 999;
`
