import React from 'react';

import AudioPlayer from '../MediaPlayer';

import { Wrapper } from './style';

const Media = ({ media, title, isOpen }) => {
  return (
    <Wrapper isOpen={isOpen}>
      <AudioPlayer isOpen={isOpen} file={media} title={title} />
    </Wrapper>
  );
};

export default Media;
