import React, { useContext, useMemo } from 'react';
import chunk from 'lodash.chunk';

import { GlobalState } from '../../store';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import { Wrapper, Row, Thumbnails, Articles } from './style';

import PostArticle from '../PostArticle';
import PostThumbnail from '../PostThumbnail';

const PostThumbnails = ({ posts }) => {
  const { breakpoint } = useWindowDimensions();
  const { activePostId } = useContext(GlobalState.State);
  const globalStateDispatch = useContext(GlobalState.Dispatch);
  const chunks = useMemo(
    () =>
      chunk(
        posts,
        { MOBILE: 1, COMPACT: 2, LAPTOP: 3, DESKTOP: 4 }[breakpoint]
      ),
    [breakpoint, posts]
  );

  return (
    <Wrapper>
      {chunks.map(chunk => {
        return (
          <Row>
            <Thumbnails>
              {chunk.map(post => (
                <PostThumbnail
                  key={post.id}
                  post={post}
                  onClick={evt => {
                    evt.preventDefault();
                    globalStateDispatch({
                      type: 'setActivePostId',
                      activePostId: activePostId === post.id ? null : post.id,
                    });
                  }}
                />
              ))}
            </Thumbnails>
            <Articles>
              {chunk.map(post => (
                <PostArticle
                  key={post.id}
                  post={post}
                  isOpen={activePostId === post.id}
                />
              ))}
            </Articles>
          </Row>
        );
      })}
    </Wrapper>
  );
};

export default PostThumbnails;
