import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import { GlobalState } from '../store';

import PostThumbnails from '../components/PostThumbnails';

export default function Home({ data }) {
  const globalStateDispatch = useContext(GlobalState.Dispatch);
  const {
    allSanityPost: { edges },
  } = data;
  const posts = edges.map(({ node }) => ({ ...node }));

  useEffect(() => {
    globalStateDispatch({
      type: 'setTheme',
      theme: {
        color: '#000',
        background: '#FFF',
      },
    });
  }, [globalStateDispatch]);

  return (
    <PostThumbnails
      posts={posts.map((post, index) => ({
        ...post,
        number: `${posts.length - index}`.padStart(
          `${posts.length}`.length,
          '0'
        ),
      }))}
    />
  );
}

export const HomePageQuery = graphql`
  query HomePage {
    allSanityPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          subtitle
          date
          thumbnail {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          category {
            title
          }
          media {
            asset {
              url
            }
          }
          credits: _rawCredits
          content {
            __typename
            ... on SanityImageBlock {
              _key
              images {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            ... on SanityTextBlock {
              _key
              text: _rawText
            }
          }
        }
      }
    }
  }
`;
