import React, { useEffect, useRef, useState, useContext } from 'react';

import { GlobalState } from '../../store';

import {
  Wrapper,
  Controls,
  PlayButton,
  MetaData,
  Title,
  Time,
  CloseButton,
  Track,
  Progress,
  Audio,
  Video,
} from './style';

const AudioPlayer = ({ title, file, isOpen }) => {
  // const src = `/assets/${file.path}`
  const {
    asset: { url: src },
  } = file;
  // const mediaType = file.extension === "mp3" ? "audio" : "video"
  const mediaType = src.endsWith('mp3') ? 'audio' : 'video';
  const { deviceHasTouchEvents } = useContext(GlobalState.State);
  const globalStateDispatch = useContext(GlobalState.Dispatch);
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const progress = duration ? currentTime / duration : 0;

  const hh = Math.floor(currentTime / 60 / 60);
  const mm = Math.floor((currentTime - hh * 60 * 60) / 60);
  const ss = Math.floor(currentTime % 60);
  const timestamp =
    `0${hh}`.substr(-2) + ':' + `0${mm}`.substr(-2) + ':' + `0${ss}`.substr(-2);

  const handleTimeUpdate = evt => {
    setCurrentTime(playerRef.current.currentTime);
    setDuration(playerRef.current.duration);
  };

  const handlePlayButtonClick = evt => {
    evt.preventDefault();
    if (playerRef.current.paused) {
      setIsPlaying(true);
      playerRef.current.play();
    } else {
      setIsPlaying(false);
      playerRef.current.pause();
    }
  };

  const handleTrackClick = evt => {
    evt.preventDefault();
    const rect = evt.target.getBoundingClientRect();
    const progress = (evt.pageX - rect.left) / rect.width;
    playerRef.current.currentTime = duration * progress;
  };

  useEffect(() => {
    const currentRef = playerRef.current;
    currentRef.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      currentRef.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [playerRef]);

  useEffect(() => {
    if (isOpen) {
      if (!deviceHasTouchEvents) {
        setIsPlaying(true);
        playerRef.current.play();
      }
    } else {
      setIsPlaying(false);
      playerRef.current.pause();
    }
  }, [deviceHasTouchEvents, playerRef, isOpen]);

  return (
    <Wrapper mediaType={mediaType}>
      {mediaType === 'audio' ? (
        <Audio src={src} ref={playerRef} autoLoad />
      ) : (
        <Video src={src} ref={playerRef} autoLoad playsInline />
      )}
      <Controls>
        <PlayButton
          onClick={handlePlayButtonClick}
          isPlaying={isPlaying}
          mediaType={mediaType}
        />
        <MetaData>
          <Title>{title}</Title>
          <Time>{timestamp}</Time>
        </MetaData>
        <CloseButton
          mediaType={mediaType}
          onClick={evt => {
            evt.preventDefault();
            globalStateDispatch({
              type: 'setActivePostId',
              activePostId: null,
            });
          }}
        />
        <Track onClick={handleTrackClick}>
          <Progress style={{ width: `${progress * 100}%` }} />
        </Track>
      </Controls>
    </Wrapper>
  );
};

export default AudioPlayer;
