import React, { useEffect, useRef } from 'react';
import BlockContentToReact from '@sanity/block-content-to-react';
import { animateScroll } from 'react-scroll';

// import parseMarkdown from '../../utils/parseMarkdown';

import Media from '../Media';
import ContentBlockMedia from '../ContentBlockMedia';

import {
  Wrapper,
  InnerWrapper,
  MetaData,
  Caption,
  Body,
  ContentBlocks,
  ContentBlock,
  // Text,
  MediaWrapper,
  // File,
} from './style';

const PostArticle = ({
  post: { title, subtitle, event, credits, media, number, content },
  isOpen,
}) => {
  const wrapperEl = useRef();

  useEffect(() => {
    if (isOpen && wrapperEl.current) {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop || 0;
      const to = wrapperEl.current.getBoundingClientRect().top;
      animateScroll.scrollTo(scrollTop + to);
    }
  }, [isOpen]);

  return media ? (
    <Media media={media} title={`${title}—${subtitle}`} isOpen={isOpen} />
  ) : (
    <Wrapper isOpen={isOpen} ref={wrapperEl}>
      <InnerWrapper>
        <MetaData>
          <h1>{title}</h1>
          <h2>&mdash;{subtitle}</h2>
          <Caption>
            <BlockContentToReact blocks={credits} />
          </Caption>
        </MetaData>
        <Body>
          {content && content.length ? (
            <ContentBlocks>
              {content.map((block, index) => (
                <ContentBlock key={index}>
                  {block.__typename === 'SanityImageBlock' ? (
                    <MediaWrapper>
                      {block.images.map((image, index) => (
                        <ContentBlockMedia key={index} image={image} />
                      ))}
                    </MediaWrapper>
                  ) : (
                    <BlockContentToReact blocks={block.text} />
                  )}
                </ContentBlock>
              ))}
            </ContentBlocks>
          ) : null}
        </Body>
      </InnerWrapper>
    </Wrapper>
  );
};

export default PostArticle;
