import styled, { css } from 'styled-components';
import { mediaQueries, typography } from '../../theme';

export const Wrapper = styled.article`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  margin: 15px 0 -5px 0;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0 15px 0;

  &:before {
    content: '';
    display: block;
    flex-shrink: 0;
    margin: 0 15px;
    padding-top: 20px;
    width: calc(100% - 30px);
  }

  &:after {
    content: '';
    display: block;
    flex-shrink: 0;
    border-bottom: solid 2px;
    margin: 0 15px;
    padding-top: 60px;
    width: calc(100% - 30px);
  }
`;

export const MetaData = styled.aside`
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 15px;
  width: calc(calc(100% - 90px) / 3);

  h3,
  h2,
  h1 {
    margin: 0;
  }

  ${mediaQueries.MOBILE} {
    width: calc(100% - 30px);
  }
`;

export const Caption = styled.div`
  margin: ${typography.LINE_HEIGHT__REM}rem 0;

  &::last-child {
    margin-bottom: 0;
  }
`;

export const Body = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 15px;
  width: calc(calc(calc(100% - 90px) / 3 * 2) + 30px);

  ${mediaQueries.MOBILE} {
    width: calc(100% - 30px);
  }
`;

export const ContentBlocks = styled.div``;

export const ContentBlock = styled.div`
  margin: 60px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  ${props =>
    props.caption &&
    css`
      font-size: ${typography.FONT_SIZE__XSMALL__REM}rem;
      line-height: ${typography.LINE_HEIGHT__XSMALL__REM}rem;
      margin: -25px 0 60px 0;
    `}
`;

export const MediaWrapper = styled.div`
  display: flex;
  margin: 25px -15px;

  ${ContentBlock}:first-child & {
    margin-top: -15px;
  }
`;

export const File = styled.div`
  flex-shrink: 1;
  margin: 15px;
  width: 100%;

  img {
    height: auto;
    width: 100%;
  }
`;
