import styled, { css } from 'styled-components';

import CloseIcon from '../../images/close.svg';
import CloseIcon__White from '../../images/close-white.svg';
import PauseIcon from '../../images/pause.svg';
import PauseIcon__White from '../../images/pause-white.svg';
import PlayIcon from '../../images/play.svg';
import PlayIcon__White from '../../images/play-white.svg';
import { mediaQueries } from '../../theme';

export const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PlayButton = styled.div`
  align-items: center;
  background-image: url(${props => (props.isPlaying ? PauseIcon : PlayIcon)});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  height: 36px;
  width: 36px;

  ${props =>
    props.mediaType === 'video' &&
    css`
      background-image: url(${props =>
        props.isPlaying ? PauseIcon__White : PlayIcon__White});
    `}
`;

export const MetaData = styled.div`
  font-size: 16px;
  flex-grow: 1;
  line-height: 18px;
  margin-left: 10px;
  margin-top: 2px;
  margin-bottom: -2px;
`;

export const Title = styled.div``;

export const Time = styled.div`
  color: #aaa;
`;

export const CloseButton = styled.div`
  align-items: center;
  background-image: url(${props =>
    props.mediaType === 'video' ? CloseIcon__White : CloseIcon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  height: 36px;
  width: 36px;
`;

export const Track = styled.div`
  background: #ccc;
  cursor: pointer;
  height: 2px;
  margin-top: 10px;
  position: relative;
  width: 100%;
`;

export const Progress = styled.div`
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
`;

export const Audio = styled.audio`
  display: none;
`;

export const Video = styled.video`
  background: black;
  bottom: 100%;
  height: calc(100vh - 64px);
  left: 0;
  padding: 64px calc(100px + 1rem) 30px calc(100px + 1rem);
  position: absolute;
  right: 0;
  width: 100%;

  ${mediaQueries.MOBILE} {
    padding: 64px 15px 30px 15px;
  }
`;

export const Wrapper = styled.div`
  background: ${props => (props.mediaType === 'audio' ? '#fff' : '#000')};
  color: ${props => (props.mediaType === 'audio' ? '#000' : '#fff')};
  border-top: #000 solid 2px;
  padding: 10px 20px;

  ${props =>
    props.mediaType === 'video' &&
    css`
      border-top-color: #fff;

      ${Title} {
        color: #fff;
      }

      ${Track} {
        background: #555;
      }

      ${Progress} {
        background: #fff;
      }
    `}
`;
